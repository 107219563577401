import axios from 'axios'
const state = {
    customersgroups: [],
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
};
const getters = {
    getCustomersGroups(state) {
        return state.customersgroups
    },
    getTableOptions(state) {
        return state.tableOptions
    },
};

const mutations = {
    setCustomersGroups(state, customersgroups) {
        state.customersgroups = customersgroups
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    }
};

const actions = {
    async fetchCustomersGroups({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get("/inventories/customer-group", {
                params,
            });
            commit('setCustomersGroups', response.data.data)
            commit('setTableData', {
                tableHeaders: [
                    "id",
                    "name",
                    "settings",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["id"],
                    ["name"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}