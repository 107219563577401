import axios from 'axios'
const state = {
    loading: false,
    suppliers: [],
    communication: [],
    supplier: {
        data: {},
        id: null,
        name: null,
        image: null,
        account: null,
        information: {
          code: {
            desc: "Barcode",
            value: "",
          },
          tax_number: {
            desc:"Tax Number",
            value:""
          },
          type: {
            desc:"Type",
            value:""
          },
          phone: {
            desc: "phone",
            value: "",
          },
          email: {
            desc: "hr.employee.email",
            value: "",
          },
          classification: {
            desc: "Classification",
            value: "",
          },
          phone2: {
            desc: "phone2",
            value: "",
          },
          address: {
            desc: "address",
            value: "",
          },
          group: {
            desc: "Group",
            value: "",
          },
          coin: {
            desc: "Currency",
            value: "",
          },
        }
    },
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
};
const getters = {
    getLoadingStatus(state) {
        return state.loading;
    },
    getSupplier(state) {
        return state.supplier
    },
    getSuppliers(state) {
        return state.suppliers
    },
    getTableOptions(state) {
        return state.tableOptions
    },
    getCommunication(state) {
        return state.communication
    }
};

const mutations = {
    setLoadingStatus(state, status) {
        state.loading = status;
    },
    setCommunication(state, communication) {
        state.communication = communication;
    },
    setSupplier(state, supplier) {
        state.supplier.data = supplier;
        state.supplier.id = supplier.id;
        state.supplier.name = supplier.name;
        state.supplier.image = supplier.image;
        state.supplier.account = supplier.account;
        state.supplier.information.code.value = supplier.code;
        state.supplier.information.tax_number.value = supplier.tax_number;
        state.supplier.information.type.value = supplier.type;
        state.supplier.information.phone.value = supplier.phone;
        state.supplier.information.email.value = supplier.email;
        state.supplier.information.classification.value = supplier.classification;
        state.supplier.information.phone2.value = supplier.phone2;
        state.supplier.information.address.value = supplier.address;
        state.supplier.information.group.value = supplier.group.name;
        state.supplier.information.coin.value = supplier.coin.name;
    },
    setSuppliers(state, suppliers) {
        state.suppliers = suppliers
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    },
};

const actions = {
    async fetchSuppliers({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get("/inventories/vendor", {
                params,
            });
            commit('setSuppliers', response.data.data)
            commit('setTableData', {
                tableHeaders: [
                    "name",
                    "phone",
                    "address",
                    "Group Name",
                    "Type",
                    "settings",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["name"],
                    ["phone"],
                    ["address"],
                    ["group","name"],
                    ["type"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchSupplier({ commit }, id) {
        try {
            commit('setLoadingStatus', true)
            const response = await axios.get(`/inventories/vendor/${id}`);
            commit('setSupplier', response.data.data)
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingStatus', false)
        }
    },
    async fetchCommunication({ commit }, id) {
        try {
            commit('setLoadingStatus', true)
            const response = await axios.get(`/inventories/vendor/${id}/communication`);
            commit('setCommunication', response.data.data)
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingStatus', false)
        }
    },
    postNewSupplier({ commit }, newSupplier) {
        return new Promise(async (resolve, reject) => {
            try {
                let form = new FormData();
                for (let key in newSupplier) {
                    if (newSupplier[key]) {
                        form.append(`${key}`, newSupplier[key])
                    }
                }
                newSupplier.id ? await axios.post(`/inventories/vendor/${newSupplier.id}`, form) : await axios.post("/inventories/vendor", form);
                resolve()
            }
            catch (err) {
                reject()
            }
        })
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}